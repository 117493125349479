/**
 * @file GIFT変更
 */
'use strict';

/**
 * @classname ChangeGift
 * @classdesc GIFT変更
 */
export default class ChangeGift {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeGift.constructor');
		this._$e = $e;
		this.init();
		$e.on('click', () => this.onClick());
	};

    init() {
        if($('.deliver_to:checked').val() === '1') {
			$('#gift-address').hide().find('.validate').removeClass('is-invalid').addClass('ignore-validate');
			$('#gift-address').find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			$('#gift-address').show().find('.validate').removeClass('ignore-validate');
		}
    }
	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('ChangeGift.onClick');
		if(this._$e.val() === '1'){
			$('#gift-address').hide().find('.validate').removeClass('is-invalid').addClass('ignore-validate');
			$('#gift-address').find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			$('#gift-address').show().find('.validate').removeClass('ignore-validate');
		}
	};
}
