'use strict';

// utility class
import ApplyClass from '../util/_apply-class';
import Common from '../util/_common';
import Basic from '../controller/_basic';

import NoZoomScroll from '../view/basic/_no-zoom-scroll';
import SmoothScroll from '../view/basic/_smooth-scroll';
import GoTop from '../view/basic/_go-top';
import SpMenu from '../view/basic/_sp-menu';
import Modal from '../view/basic/_modal';
import Slider from '../view/basic/_slider';
import Search from '../view/basic/_search';
import Accordion from '../view/basic/_accordion';
import Tooltip from '../view/basic/_tooltip';
import BtnClose from '../view/basic/_btn-close';
import TableScrollbar from '../view/basic/_table-scrollbar';
import CartQuantityChange from '../view/basic/_cart-quantity-change';


$(() => {
	const BasicController = new Basic();
	// デバイス、ブラウザ判定
	Common.addHtmlClass();
	objectFitImages();
	$('body.page').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(NoZoomScroll, $e, [$e, BasicController]);
	});
	//スムーススクロール
	$('a[href^="#"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SmoothScroll, $e, [$e, BasicController]);
	});
	// TOPへ戻る
	$('.anchor--go-top').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(GoTop, $e, [$e, BasicController]);
	});
	// SP MENU
	$('.btn--sp-menu').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SpMenu, $e, [$e, BasicController]);
	});
	// Modal
	$('[data-modal=true]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Modal, $e, [$e, BasicController]);
	});
	// Slider
	$('.slider[data-option-id=1]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Slider, $e, [$e, BasicController]);
	});
	// 検索ボタン
	$('.btn--search').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Search, $e, [$e, BasicController]);
	});
	// アコーディオン
	$('.anchor--accordion').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Accordion, $e, [$e, BasicController]);
	});
	// アコーディオン（ボタン）
	$('.btn--accordion').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Accordion, $e, [$e, BasicController]);
	});
	// ツールチップ
	$('.anchor--tooltip').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Tooltip, $e, [$e, BasicController]);
	});
	// アラートクローズ
	$('.alert .btn--close').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BtnClose, $e, [$e, BasicController]);
	});
	// テーブルスクロールバー
	$('.scroll-bar').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(TableScrollbar, $e, [$e, BasicController]);
	});
	// カート数量変更
	$('.cart-quantity > select').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CartQuantityChange, $e, [$e, BasicController]);
	});
});

//object-fit polyfill
/*! npm.im/object-fit-images 3.2.4 */
var objectFitImages = (function () {
	'use strict';

	var OFI = 'bfred-it:object-fit-images';
	var propRegex = /(object-fit|object-position)\s*:\s*([-.\w\s%]+)/g;
	var testImg = typeof Image === 'undefined' ? {style: {'object-position': 1}} : new Image();
	var supportsObjectFit = 'object-fit' in testImg.style;
	var supportsObjectPosition = 'object-position' in testImg.style;
	var supportsOFI = 'background-size' in testImg.style;
	var supportsCurrentSrc = typeof testImg.currentSrc === 'string';
	var nativeGetAttribute = testImg.getAttribute;
	var nativeSetAttribute = testImg.setAttribute;
	var autoModeEnabled = false;

	function createPlaceholder(w, h) {
		return ("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + w + "' height='" + h + "'%3E%3C/svg%3E");
	}

	function polyfillCurrentSrc(el) {
		if (el.srcset && !supportsCurrentSrc && window.picturefill) {
			var pf = window.picturefill._;
			// parse srcset with picturefill where currentSrc isn't available
			if (!el[pf.ns] || !el[pf.ns].evaled) {
				// force synchronous srcset parsing
				pf.fillImg(el, {reselect: true});
			}

			if (!el[pf.ns].curSrc) {
				// force picturefill to parse srcset
				el[pf.ns].supported = false;
				pf.fillImg(el, {reselect: true});
			}

			// retrieve parsed currentSrc, if any
			el.currentSrc = el[pf.ns].curSrc || el.src;
		}
	}

	function getStyle(el) {
		var style = getComputedStyle(el).fontFamily;
		var parsed;
		var props = {};
		while ((parsed = propRegex.exec(style)) !== null) {
			props[parsed[1]] = parsed[2];
		}
		return props;
	}

	function setPlaceholder(img, width, height) {
		// Default: fill width, no height
		var placeholder = createPlaceholder(width || 1, height || 0);

		// Only set placeholder if it's different
		if (nativeGetAttribute.call(img, 'src') !== placeholder) {
			nativeSetAttribute.call(img, 'src', placeholder);
		}
	}

	function onImageReady(img, callback) {
		// naturalWidth is only available when the image headers are loaded,
		// this loop will poll it every 100ms.
		if (img.naturalWidth) {
			callback(img);
		} else {
			setTimeout(onImageReady, 100, img, callback);
		}
	}

	function fixOne(el) {
		var style = getStyle(el);
		var ofi = el[OFI];
		style['object-fit'] = style['object-fit'] || 'fill'; // default value

		// Avoid running where unnecessary, unless OFI had already done its deed
		if (!ofi.img) {
			// fill is the default behavior so no action is necessary
			if (style['object-fit'] === 'fill') {
				return;
			}

			// Where object-fit is supported and object-position isn't (Safari < 10)
			if (
				!ofi.skipTest && // unless user wants to apply regardless of browser support
				supportsObjectFit && // if browser already supports object-fit
				!style['object-position'] // unless object-position is used
			) {
				return;
			}
		}

		// keep a clone in memory while resetting the original to a blank
		if (!ofi.img) {
			ofi.img = new Image(el.width, el.height);
			ofi.img.srcset = nativeGetAttribute.call(el, "data-ofi-srcset") || el.srcset;
			ofi.img.src = nativeGetAttribute.call(el, "data-ofi-src") || el.src;

			// preserve for any future cloneNode calls
			// https://github.com/bfred-it/object-fit-images/issues/53
			nativeSetAttribute.call(el, "data-ofi-src", el.src);
			if (el.srcset) {
				nativeSetAttribute.call(el, "data-ofi-srcset", el.srcset);
			}

			setPlaceholder(el, el.naturalWidth || el.width, el.naturalHeight || el.height);

			// remove srcset because it overrides src
			if (el.srcset) {
				el.srcset = '';
			}
			try {
				keepSrcUsable(el);
			} catch (err) {
				if (window.console) {
					console.warn('https://bit.ly/ofi-old-browser');
				}
			}
		}

		polyfillCurrentSrc(ofi.img);

		el.style.backgroundImage = "url(\"" + ((ofi.img.currentSrc || ofi.img.src).replace(/"/g, '\\"')) + "\")";
		el.style.backgroundPosition = style['object-position'] || 'center';
		el.style.backgroundRepeat = 'no-repeat';
		el.style.backgroundOrigin = 'content-box';

		if (/scale-down/.test(style['object-fit'])) {
			onImageReady(ofi.img, function () {
				if (ofi.img.naturalWidth > el.width || ofi.img.naturalHeight > el.height) {
					el.style.backgroundSize = 'contain';
				} else {
					el.style.backgroundSize = 'auto';
				}
			});
		} else {
			el.style.backgroundSize = style['object-fit'].replace('none', 'auto').replace('fill', '100% 100%');
		}

		onImageReady(ofi.img, function (img) {
			setPlaceholder(el, img.naturalWidth, img.naturalHeight);
		});
	}

	function keepSrcUsable(el) {
		var descriptors = {
			get: function get(prop) {
				return el[OFI].img[prop ? prop : 'src'];
			},
			set: function set(value, prop) {
				el[OFI].img[prop ? prop : 'src'] = value;
				nativeSetAttribute.call(el, ("data-ofi-" + prop), value); // preserve for any future cloneNode
				fixOne(el);
				return value;
			}
		};
		Object.defineProperty(el, 'src', descriptors);
		Object.defineProperty(el, 'currentSrc', {
			get: function () { return descriptors.get('currentSrc'); }
		});
		Object.defineProperty(el, 'srcset', {
			get: function () { return descriptors.get('srcset'); },
			set: function (ss) { return descriptors.set(ss, 'srcset'); }
		});
	}

	function hijackAttributes() {
		function getOfiImageMaybe(el, name) {
			return el[OFI] && el[OFI].img && (name === 'src' || name === 'srcset') ? el[OFI].img : el;
		}
		if (!supportsObjectPosition) {
			HTMLImageElement.prototype.getAttribute = function (name) {
				return nativeGetAttribute.call(getOfiImageMaybe(this, name), name);
			};

			HTMLImageElement.prototype.setAttribute = function (name, value) {
				return nativeSetAttribute.call(getOfiImageMaybe(this, name), name, String(value));
			};
		}
	}

	function fix(imgs, opts) {
		var startAutoMode = !autoModeEnabled && !imgs;
		opts = opts || {};
		imgs = imgs || 'img';

		if ((supportsObjectPosition && !opts.skipTest) || !supportsOFI) {
			return false;
		}

		// use imgs as a selector or just select all images
		if (imgs === 'img') {
			imgs = document.getElementsByTagName('img');
		} else if (typeof imgs === 'string') {
			imgs = document.querySelectorAll(imgs);
		} else if (!('length' in imgs)) {
			imgs = [imgs];
		}

		// apply fix to all
		for (var i = 0; i < imgs.length; i++) {
			imgs[i][OFI] = imgs[i][OFI] || {
				skipTest: opts.skipTest
			};
			fixOne(imgs[i]);
		}

		if (startAutoMode) {
			document.body.addEventListener('load', function (e) {
				if (e.target.tagName === 'IMG') {
					fix(e.target, {
						skipTest: opts.skipTest
					});
				}
			}, true);
			autoModeEnabled = true;
			imgs = 'img'; // reset to a generic selector for watchMQ
		}

		// if requested, watch media queries for object-fit change
		if (opts.watchMQ) {
			window.addEventListener('resize', fix.bind(null, imgs, {
				skipTest: opts.skipTest
			}));
		}
	}

	fix.supportsObjectFit = supportsObjectFit;
	fix.supportsObjectPosition = supportsObjectPosition;

	hijackAttributes();

	return fix;

}());

// array includes polyfill
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
		value: function(searchElement, fromIndex) {

			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);
			var len = o.length >>> 0;

			if (len === 0) {
				return false;
			}

			var n = fromIndex | 0;
			var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

			while (k < len) {
				if (o[k] === searchElement) {
					return true;
				}
				k++;
			}

			return false;
		}
	});
}
