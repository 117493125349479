/**
 * @file バリデーションチェック（チェックボックス）
 */
'use strict';
// utility class
import Common from '../../util/_common';


/**
 * @classname FormValidateView
 * @classdesc バリデーションチェック（チェックボックス）
 */
export default class FormValidateView {

	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormValidateView.constructor');
		this._controller = controller;
		this._$e		 = $e;
		this._$checkbox  = $e.find('input[type=checkbox]');
		this._$form				= this._$e.closest('form');
		this._$formGroup		= this._$e.closest('.form-group');
		this._$checkboxGroup	= this._$formGroup.find('input[type=checkbox]');
		this._$errortip			= this._$formGroup.find('.error-tip');
		this._$errortipInner	= this._$errortip.find('.error-tip__inner');
		this._controller.on('validate', () => this.onClick());
		$e.on('click', () => {
			this.onClick();
		});
	};


	/**
	 * バリデーション実行
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	onClick() {
		if(this._controller._$form && this._controller._$form.attr('id') !== this._$form.attr('id')) return;
		console.log('FormValidateView.onClick()');
		if(this._$e.hasClass('ignore-validate')){
			console.log('ignore validate');
		}else{
			this._error = [];
			let cnt = 0;
			this._$checkboxGroup.each((idx, e) => {
				const $e = $(e);
				if ($e.prop('checked')) {
					cnt++;
				}
			});
			if (cnt === 0) {
				this.showError();
			}else{
				this.hideError();
			}
		}
	};


	/**
	 * エラーを表示する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	showError() {
		this._$checkboxGroup.addClass('is-invalid');
		this._$errortipInner.html('<p>この項目は必須です</p>');
		this._$errortip.css('top', '-30px').addClass('show');
	};


	/**
	 * エラーを非表示にする
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	hideError() {
		this._$checkboxGroup.removeClass('is-invalid');
		this._$errortip.removeClass('show').css('top', '-30px');
		this._$errortipInner.html('');
	};
}
