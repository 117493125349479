/**
 * @file ズーム禁止、スクロール禁止、ダブルタップ禁止
 *
 */
'use strict';

/**
 * @classname NoZoomScroll
 * @classdesc ズーム禁止、スクロール禁止、ダブルタップ禁止
 */
export default class NoZoomScroll {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('NoZoomScroll.constructor');
		// ズーム禁止
		document.documentElement.addEventListener('touchstart', function (e) {
			if (e.touches.length >= 2) {e.preventDefault();}
		},{passive: false});
		// ダブルタップの拡大禁止
		let t = 0;
		document.documentElement.addEventListener('touchend', function (e) {
			const now = new Date().getTime();
			if ((now - t) < 350){
				e.preventDefault();
			}
			t = now;
		}, false);
	};
}
