/**
 * @file 年月変更
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname DateChange
 * @classdesc 年月変更
 */
export default class DateChange {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('DateChange.constructor');
		this._$e = $e;
		this._selectedDay = null;
		$e.on('change', () => this.onChange());
	};


	/**
	 * changeのコールバック
	 *
	 * @memberof DateChange
	 */
	onChange() {
		console.log('DateChange.onChange');
		const year = parseInt($('#year').val());
		const month = parseInt($('#month').val());
		this._selectedDay = parseInt($('#day').val());
		const day = this.getDay(year, month);
		if(day){
			this.setDay(day);
		}else{
			this.setBlank();
		}
	};


	/**
	 * その月の日数を返す
	 *
	 * @param {number} year - 年
	 * @param {number} month - 月
	 * @return {number} day - 日
	 * @memberof DateChange
	 */
	getDay(year, month){
		console.log('DateChange.getDay');
		let lastday = ['', 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0){
			lastday[2] = 29;
		}
		return lastday[month];
	};


	/**
	 * 日をセットする
	 *
	 * @param {number} day - 日
	 * @memberof DateChange
	 */
	setDay(day) {
		console.log('DateChange.setDay');
		let days = '<option value="">--</option>';
		for(let i = 1; i <= day; i++){
			if(i === this._selectedDay){
				days += `<option value="${Common.zeroPadding(i, 2)}" selected>${Common.zeroPadding(i, 2)}</option>`;
			}else{
				days += `<option value="${Common.zeroPadding(i, 2)}">${Common.zeroPadding(i, 2)}</option>`;
			}
		}
		$('#day').html(days);
		this._selectedDay = null;
	};


	/**
	 * ブランクをセットする
	 *
	 * @memberof DateChange
	 */
	setBlank() {
		console.log('DateChange.setBlank');
		const days = '<option value="">--</option>';
		$('#day').html(days);
		this._selectedDay = null;
	};
}