/**
 * @file TOPへ戻る
 *
 */
'use strict';

/**
 * @classname GoTop
 * @classdesc TOPへ戻る
 */
export default class GoTop {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('GoTop.constructor');
		this._$e = $e;
		this._height = $(window).height() * .7;
		this._scroll = 0;
		$(window).on('scroll', () => this.onScroll());
	};


	/**
	 * スクロールのコールバック
	 *
	 * @memberof GoTop
	 */
	onScroll() {
		this._scroll = $(window).scrollTop();
		if(this._scroll > this._height){
			this._$e.fadeIn();
		}else{
			this._$e.fadeOut();
		}
	}
}
