'use strict';

/**
 * @classname CartQuantityChange
 * @classdesc カート数量変更
 */
export default class CartQuantityChange {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('CartQuantityChange.constructor');
		this._$e = $e;
		this._unitPrice = $e.attr('data-unit-price');
		$e.on('change', () => this.onChange());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof CartQuantityChange
	 * @return {undefined}
	 */
	onChange() {
		console.log('CartQuantityChange.onChange');
		const quantity = this._$e.val();
		const subTotal = quantity * this._unitPrice;
		this._$e.closest('.list__item').find('.product-total').text(`${subTotal.toLocaleString()}`);
		let total = 0;
		$('.list--cart .list__item .product-total').each((idx, e) => {
			const price = parseInt($(e).text().replace(/,/g, ''));
			total += price;
		});
		$('.cart-total').text(`${total.toLocaleString()}`);
		const tax = Math.ceil(total / 11);
		$('.cart-tax').text(`${tax.toLocaleString()}`);
	};
};
