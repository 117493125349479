/**
 * @file ユーザータイプ変更
 */
'use strict';

/**
 * @classname ChangeUserType
 * @classdesc ユーザータイプ変更
 */
export default class ChangeUserType {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('ChangeUserType.constructor');
		this._$e = $e;
		this.onClick();
		$e.on('click', () => this.onClick());
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('ChangeUserType.onClick');
		let value = $('[name=user_type]:checked').val();
		if(this._$e.attr('type') === 'hidden'){
			value = $('[name=user_type]').val();
		}
		const $company = $('.block--company');
		const $individual = $('.block--individual');
		if(value === '1'){
			$company.removeClass('hide').find('.validate').removeClass('ignore-validate');
			$individual.addClass('hide').find('.validate').addClass('ignore-validate').removeClass('is-invalid');
			$individual.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			$company.addClass('hide').find('.validate').addClass('ignore-validate').removeClass('is-invalid');
			$company.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$individual.removeClass('hide').find('.validate').removeClass('ignore-validate');
		}
	};
}
