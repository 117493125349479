'use strict';

/**
 * @classname Tooltip
 * @classdesc もっと見る
 */
export default class Tooltip {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Tooltip.constructor');
		this._$e = $e;
		this._text = $e.attr('title');
		this._$tooltip = `<div class="tooltip"></div>`;
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof Tooltip
	 * @return {undefined}
	 */
	onClick() {
		console.log('Tooltip.onClick');
		if($('.tooltip')[0]){
			$('.tooltip').remove();
		}else{
			const offset = this._$e.offset();
			console.log(offset);
			$('body').append(this._$tooltip);
			const $tooltip = $('.tooltip')
			$tooltip.html(this._text);
			const height = $tooltip.height();
			$tooltip.css('top', `${offset.top - 30 - height}px`).css('left', `${offset.left - 45}px`);
			$tooltip.on('click', () => {
				$('.tooltip').remove();
			});
		}
	};
};