/**
 * @file Form Controller
 */

'use strict';
import Listener from '../util/_listener';
import FormModel from '../model/_form-model';

let instance = null;

/**
 * @classname Form
 * @classdesc Form Controller
 */
export default class Form {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Form.constructor');

		this._model = new FormModel();

		this._listener = new Listener();
		this._$form = null;
		this._submit = true;
		this._locationNum = null;
		this._prNum = null;
		this._projectNo = 0;

		this._model.on('uploadImageComplete', () => {
			this._imageData = this._model._data;
			this._listener.trigger('uploadImageComplete');
		});
		this._model.on('deleteImageComplete', () => {
			this._listener.trigger('deleteImageComplete');
		});

		instance = this;
		return instance;
	};


	/**
	 * 前処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePreProcessing($form) {
		console.log('Form.completePreProcessing');
		this._$form = $form;
		this._listener.trigger('completePreProcessing');
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validate($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validate');
		setTimeout(() => {
			this._listener.trigger('completeValidate');
		}, 500);
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validateModal($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validate');
	};


	/**
	 * 後処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePostProcessing($form) {
		console.log('Form.completePostProcessing');
		this._$form = $form;
		this._listener.trigger('completePostProcessing');
	};


	/**
	 * 後処理でのエラー
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	hasErrorPostProcessing() {
		console.log('Form.hasErrorPostProcessing');
		this._listener.trigger('hasErrorPostProcessing');
	};



	/**
	 * 後処理でのエラー
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	calculatePrice() {
		console.log('Form.calculatePrice');
		let total = 0;
		$('.list--cart .list__item .text--total').each((idx, e) => {
			const $e = $(e);
			total = total + parseInt($e.text().replace(/,/g, ''));
		});
		console.log(total);
		const tax = Math.floor(total / 11);
		$('.td.price strong').text(`${total.toLocaleString()}`);
		$('.td.tax strong').text(`${tax.toLocaleString()}`);
		$('#cart_price').val(`total`);
		if($('.list--cart .list__item').length === 0){
			$('.text--no-product').show();
		}
	};


	/**
	 * listener on
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
