/**
 * @file ユーザータイプ変更
 */
'use strict';

/**
 * @classname ChangeInvoiceFlg
 * @classdesc 請求書フラグ変更
 */
export default class ChangeInvoiceFlg {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeInvoiceFlg.constructor');
		this._$e = $e;
		this.onClick();
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('ChangeInvoiceFlg.onClick');
		const value = $('[name=invoice_type]:checked').val();
		const $invoice = $('.block--invoice');
		if(value === '2'){
            $invoice.removeClass('hide');
            // ここに
            $('.invoice_company_name.validate').removeClass('ignore-validate');
            $('.invoice_dept_name.validate').removeClass('ignore-validate');
            $('.invoice_post_name.validate').removeClass('ignore-validate');
            $('.invoice_name.validate').removeClass('ignore-validate');
            $('.invoice_tel.validate').removeClass('ignore-validate');
            $('.invoice_honorific.validate').removeClass('ignore-validate');
			if($('#oversea_flg_invoice').prop('checked')){
				console.log('海外住所利用');
				$('.block--japanese-address-invoice').find('.validate').addClass('ignore-validate');
				$('.block--overseas-address-invoice').find('.validate').removeClass('ignore-validate');
			}else{
				console.log('国内住所利用');
				$('.block--japanese-address-invoice').find('.validate').removeClass('ignore-validate');
				$('.block--overseas-address-invoice').find('.validate').addClass('ignore-validate');
			}
		}else{
			$invoice.addClass('hide').find('.validate').addClass('ignore-validate').removeClass('is-invalid');
			$invoice.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}
	};
}
