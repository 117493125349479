/**
 * @file カート数量変更
 */
'use strict';

/**
 * @classname BtnChangeNum
 * @classdesc カート数量変更
 */
export default class BtnChangeNum {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnChangeNum.constructor');
		this._max = window.const.MAX_CART_QUANTITY;
		this._$e = $e;
		this._controller = controller;
		this._$quantity = $('#quantity');
		this._controller._quantity = parseInt(this._$quantity.val());
		$e.on('click', () => this.onClick());
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		if(this._$e.hasClass('disabled')) return;
		console.log('BtnChangeNum.onClick');
		if(this._$e.hasClass('btn--plus')){
			this._controller._quantity ++;
			this._$quantity.val(this._controller._quantity);
			$('.btn--minus').removeClass('disabled');
			if(this._controller._quantity >= this._max){
				this._$e.addClass('disabled');
			}
		}else{
			this._controller._quantity --;
			this._$quantity.val(this._controller._quantity);
			$('.btn--plus').removeClass('disabled');
			if(this._controller._quantity <= 1){
				this._$e.addClass('disabled');
			}
		}
	};
}
