'use strict';

import Swiper from 'swiper';
import Common from '../../util/_common';

/**
 * @classname Slider
 * @classdesc スライダー
 */
export default class Slider {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		this._$e = $e;
		this._swiper = new Swiper($e, this.getOptions());
		this._thumbs = [];
	};


	/**
	 * オプションの取得
	 *
	 * @memberof Slider
	 * @return {undefined}
	 */
	getOptions() {
		console.log('Slider.getOptions');
		let options;
		options = {
			spaceBetween: 0,
			slidesPerView: 1,
			navigation: {
				nextEl: '.swiper-btn-next',
				prevEl: '.swiper-btn-prev'
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					const no = Common.zeroPadding(index + 1, 2);
					return '<span class="' + className + '">' + no + '</span>';
				},
			},
			centeredSlides: true,
			loop: true
		};
		return options;
	};
};
