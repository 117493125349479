/**
 * @file 文字カウンター
 */
'use strict';

/**
 * @classname FormCounterView
 * @classdesc 文字カウンター
 */
export default class FormZipView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormCounterView.constructor');
		this._$e = $e;
		this._max = parseInt($e.attr('maxlength'));
		this._$e.removeAttr('maxlength');
		const $wrap = $e.closest('.form-group__content__inner');
		$wrap.append(`<p class="text text--counter">残り<span></span>文字</p>`);
		this._$counter = $wrap.find('.text--counter span');
		$e.on('keyup', () => this.calculate());
		$e.on('blur', () => this.calculate());
		this.calculate();
	};


	/**
	 * keyupのコールバック
	 *
	 * @memberof FormDiffModalView
	 * @return {undefined}
	 */
	calculate() {
		console.log('FormCounterView.calculate');
		let value = this._$e.val();
		if(value !== ''){
			let len = value.length;
			if(len > this._max){
				value = value.substr(0, this._max);
				this._$e.val(value);
				len = this._max;
			}
			const rest = this._max - len;
			console.log(rest);
			this._$counter.text(rest);
		}else{
			this._$counter.text(this._max);
		}
	};
}
