/**
 * @file 商品タイプ選択
 */
'use strict';

/**
 * @classname SelectProductType
 * @classdesc 商品タイプ選択
 */
export default class SelectProductType {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('SelectProductType.constructor');
		this._$e = $e;
		this._controller = controller;
		this._max = window.const.MAX_CART_QUANTITY;
		this._$quantity = $('#quantity');
		this._productType = $e.attr('data-product-type');
		this._salesType = $e.attr('data-sales-type');
		this._cartUrl = $e.attr('data-cart-add-url');
		$e.on('click', () => this.onClick());
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		if(this._$e.hasClass('active')) return;
		console.log('SelectProductType.onClick');
		$('.list--detail-btn .list__item').removeClass('active');
		this._$e.addClass('active');
		$('.btn[type=submit]').removeClass('disabled');
		$('#product_type').val(this._productType);
		$('#sales_type').val(this._salesType);
		$('#form-add-cart').attr('action', this._cartUrl);
		const $btnPlus = $('.btn--plus');
		const $btnMinus = $('.btn--minus');
		if(this._productType === '1'){
			if(this._controller._quantity <= 1){
				$btnPlus.removeClass('disabled');
				$btnMinus.addClass('disabled');
			}else if(this._controller._quantity >= this._max){
				$btnPlus.addClass('disabled');
				$btnMinus.removeClass('disabled');
			}else{
				$btnPlus.removeClass('disabled');
				$btnMinus.removeClass('disabled');
			}
		}else{
			this._controller._quantity = 1;
			this._$quantity.val(1);
			$btnPlus.addClass('disabled');
			$btnMinus.addClass('disabled');
		}
	};
}
