/**
 * @file カート商品削除
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname RemoveCartProduct
 * @classdesc カート商品削除
 */
export default class RemoveCartProduct {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('RemoveCartProduct.constructor');
		this._$e = $e;
		this._controller = controller;
		this._productId = $e.attr('data-product-id');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('RemoveCartProduct.onClick');
		this._$e.closest('.list__item').remove();
		$('#product_id').val(this._productId);
		Common.submit($('#form-cart-delete'));
	};
}
