/**
 * @file Form送信
 */
'use strict';
// utility class
import Common from '../../util/_common';
import FormPreRegist from './form/_form-pre-regist';
import FormRegist from './form/_form-regist';

/**
 * @classname Submit
 * @classdesc フォームサブミット
 */
export default class Submit {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('Submit.constructor');
		this._$e = $e;
		this._controller = controller;
		this._form = $e.attr('data-form');
		this._$form = $(this._form);
		this._process = $e.attr('data-process') || null;
		this._timing = $e.attr('data-timing') || null;
		this._submit = true;
		this._validate = false;
		if(this._process === 'true') {
			// 個別処理ファイルのアクティベート
			switch(this._form){
				case '#form-pre-regist':
					this._processing = new FormPreRegist(controller);
					break;
				case '#form-email-change':
					this._processing = new FormPreRegist(controller);
					break;
				case '#form-pass-forget':
					this._processing = new FormPreRegist(controller);
					break;
				case '#form-regist':
					this._processing = new FormRegist(controller);
					break;
				case '#form-edit':
					this._processing = new FormRegist(controller);
					break;
			}
		}
		this._$e.on('click', (e) => {
			e.preventDefault();
			if(this._$e.hasClass('disabled')) return;
			if(!$e.hasClass('clicked')){
				$e.addClass('clicked');
				$e.append('<i class="fa fa-spinner fa-pulse ml-2">');
				this._flg = false;
				this.onClick();
			}
		});
		this._flg = true;
		this._controller.on('completePreProcessing', () => this.validate());
		this._controller.on('completeValidate', () => this.completeValidate());
		this._controller.on('completePostProcessing', () => this.addMinutes());
		this._controller.on('hasErrorPostProcessing', () => this.initialize());
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	onClick() {
		console.log('Submit.onClick');
		console.log(this._$e);
		console.log(this._form);
		if(this._process === 'true'){
			if(this._timing === 'pre' || this._timing === 'both') {
				console.log('前処理実施');
				this._process.preProcessing(this._$form);
			}else{
				console.log('前処理SKIP');
				this.validate(this._$form);
			}
		}else{
			console.log('前処理SKIP');
			this.validate(this._$form);
		}
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	validate() {
		if(this._validate) return;
		this._validate = true;
		console.log('Submit.validate');
		console.log('バリデーションチェック開始');
		this._controller.validate(this._$form);
	};


	/**
	 * バリデーション完了
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	completeValidate() {
		console.log('Submit.completeValidate');
		console.log('バリデーションチェック完了');
		setTimeout(() => {
			if(this._$form.find('.validate').hasClass('is-invalid')) this._submit = false;
			if(this._$form.find('.validate-checkbox').hasClass('is-invalid')) this._submit = false;
			if(this._$form !== this._controller._$form) return;
			if(this._submit){
				console.log('エラーチェック：エラーなし');
				this.postProcessing();
			}else{
				Common.showErrorModal('入力項目に誤りがあります');
				this._flg = true;
				this.initialize();
			}
		},300);
	};


	/**
	 * 後処理
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	postProcessing() {
		console.log('Submit.postProcessing');
		console.log(`process: ${this._process}`);
		if(this._process === 'true'){
			if(this._timing === 'post' || this._timing === 'both') {
				console.log('後処理実施');
				this._processing.postProcessing(this._$form);
			}else{
				console.log('後処理SKIP');
				this.addMinutes();
			}
		}else{
			console.log('後処理SKIP');
			this.addMinutes();
		}
	};


	/**
	 * 分を追加する
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	addMinutes() {
		console.log('Submit.addMinutes');
		$('input[data-time=true]').each((idx, e) => {
			let val = $(e).val();
			if(val.length === 16){
				val += ':00';
				console.log(val);
				$(this).val(val);
			}
		});
		this.submit();
	};


	/**
	 * submit
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	submit() {
		console.log('Submit.submit');
		$('.ignore-validate').removeAttr('name');
		console.log('サブミット');
		setTimeout(() => {
			Common.submit(this._$form);
		}, 100);
		if(	this._$form.attr('action') === '#' ||
			this._$form.attr('action') === '' ||
			this._$form.attr('action') === '__TBA__')
		{
			setTimeout(() => this.initialize(), 1000);
		}
	};


	/**
	 * 初期化
	 *
	 * @memberof Submit
	 * @return {undefined}
	 */
	initialize() {
		console.log('Submit.initialize');
		this._submit = true;
		this._validate = false;
		this._controller._$form = null;
		this._flg = true;
		this._$e.removeClass('clicked').find('.fa-spinner.fa-pulse').remove();
	};
}
