/**
 * @file パスワード表示/非表示切替
 */
'use strict';

/**
 * @classname ShowPassword
 * @classdesc パスワード表示/非表示切替
 */
export default class ShowPassword {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ShowPassword.constructor');
		this._$e = $e;
		this._$input = $e.parent().find('input');
		this._$icon = $e.find('.icon');
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick(e) {
		console.log('ShowPassword.onClick');
		if(this._$input.attr('type') === 'password' ) {
			this._$input.attr('type', 'text');
			this._$icon.addClass('close');
		}else{
			this._$input.attr('type', 'password');
			this._$icon.removeClass('close');
		}
	};
}
