/**
 * @file 海外住所変更
 */
'use strict';

/**
 * @classname ChangeOverseasFlg
 * @classdesc 海外住所変更
 */
export default class ChangeOverseasFlg {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('ChangeOverseasFlg.constructor');
		this._$e = $e;
		this.onClick();
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('ChangeOverseasFlg.onClick');
		if($('body').hasClass('page-edit') && $('[name=user_type]').val() === '2'){
		    return;
        }else{
            const id = this._$e.attr('id');
            let $japaneseAddress = $('.block--japanese-address');
            let $overseasAddress = $('.block--overseas-address');
            const $tel =  $('#tel');
            const $telMobile =  $('#tel_mobile');
            const $fax =  $('#fax');
            const placeholders = ['03-1234-56178', '090-1234-5678', '03-1234-5679'];
            if(id === 'oversea_flg_invoice'){
                $japaneseAddress = $('.block--japanese-address-invoice');
                $overseasAddress = $('.block--overseas-address-invoice');
            }
            if(this._$e.prop('checked')){
                $japaneseAddress.hide().find('.validate').removeClass('is-invalid').addClass('ignore-validate');
                $japaneseAddress.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
                $overseasAddress.show().find('.validate').removeClass('ignore-validate');
                if(id !== 'oversea_flg_invoice'){
                    $tel.attr('maxlength', '20').attr('placeholder', '');
                    $telMobile.attr('maxlength', '20').attr('placeholder', '');
                    $fax.attr('maxlength', '20').attr('placeholder', '');
                }
            }else{
                $overseasAddress.hide().find('.validate').removeClass('is-invalid').addClass('ignore-validate');
                $overseasAddress.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
                $japaneseAddress.show().find('.validate').removeClass('ignore-validate');
                if(id !== 'oversea_flg_invoice'){
                    $tel.attr('maxlength', '13').attr('placeholder', placeholders[0]);
                    $telMobile.attr('maxlength', '13').attr('placeholder', placeholders[1]);
                    $fax.attr('maxlength', '13').attr('placeholder', placeholders[2]);
                }
            }
        }
	};
}
