/**
 * @file Basic Controller
 */

'use strict';
import Listener from '../util/_listener';
import BasicModel from '../model/_basic-model';
import Common from '../util/_common';

let instance = null;

/**
 * @classname Basic
 * @classdesc Basic Controller
 */
export default class Basic {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Basic.constructor');
		this._listener = new Listener();
		this._model = new BasicModel();

		this._model.on('favoriteComplete', () => {
			this._listener.trigger('favoriteComplete');
		});
		this._model.on('blockComplete', () => {
			this._listener.trigger('blockComplete');
		});
		this._model.on('blockOffComplete', () => {
			this._listener.trigger('blockOffComplete');
		});
		this._model.on('postMessageComplete', () => {
			this._listener.trigger('postMessageComplete');
		});
		this._model.on('postAttachmentComplete', () => {
			this._data = this._model._data;
			this._listener.trigger('postAttachmentComplete');
		});
		this._model.on('getChartDataComplete', () => {
			this._data = this._model._data;
			this._listener.trigger('getChartDataComplete');
		});
		this._model.on('favoriteError', () => {
			this._listener.trigger('favoriteError');
		});

		instance = this;
		return instance;
	};


	/**
	 * お気に入り
	 *
	 * @memberof Basic
	 * @param {string} jobId - 求人
	 * @return {undefined}
	 */
	favorite(jobId) {
		console.log('Basic.favorite');
		this._model.favorite(jobId);
	};


	/**
	 * ブロック
	 *
	 * @memberof Basic
	 * @param {string} jobId - 求人
	 * @return {undefined}
	 */
	block(jobId) {
		console.log(`Basic.block / ${jobId}`);
		this._model.block(jobId);
	};


	/**
	 * ブロック解除（詳細ページ）
	 *
	 * @memberof Basic
	 * @param {string} jobId - 求人
	 * @return {undefined}
	 */
	blockOff(jobId) {
		console.log('Basic.blockOff');
		this._model.blockOff(jobId);
	};


	/**
	 * ページ移動
	 *
	 * @memberof Basic
	 * @return {undefined}
	 */
	movePage(pageNo) {
		console.log('Basic.movePage');
		this._pageNo = pageNo;
		console.log(this._pageNo);
		this._listener.trigger('movePage');
	};


	/**
	 * メッセージ投稿
	 *
	 * @memberof Basic
	 * @param {string} message - メッセージ
	 * @return {undefined}
	 */
	postMessage(message) {
		console.log('Basic.postMessage');
		this._model.postMessage(message);
	};


	/**
	 * 添付アップロード
	 *
	 * @memberof Basic
	 * @param {blob} file - ファイル
	 * @return {undefined}
	 */
	postAttachment(file) {
		console.log('Form.postAttachment');
		this._model.postAttachment(file);
	};


	/**
	 * 検索結果
	 *
	 * @memberof Basic
	 * @return {undefined}
	 */
	searchResult(page){
		console.log('Form.searchResult');
		let url = `${window.const.URL.SEARCH}`;
		if($('.pagination').hasClass('pagination--self')){
			url = Common.getCurrentURL();
		}
		let prefix = '?';
		// 職種
		const occupation = $('.occupation_id').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(occupation && occupation !== ''){
			url += `${prefix}${window.const.PARAM.OCCUPATION}=${occupation}`;
			prefix = '&';
		}
		// 勤務地（エリア）
		const area = $('.area').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(area && area !== ''){
			url += `${prefix}${window.const.PARAM.AREA}=${area}`;
			prefix = '&';
		}
		// 勤務地（都道府県）
		const prefecture_id = $('.prefecture_id').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(prefecture_id && prefecture_id !== ''){
			url += `${prefix}${window.const.PARAM.PREFECTURE}=${prefecture_id}`;
			prefix = '&';
		}
		// 業種
		const industry_id = $('.industry_id').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(industry_id && industry_id !== ''){
			url += `${prefix}${window.const.PARAM.INDUSTRY_ID}=${industry_id}`;
			prefix = '&';
		}
		const employment_type_id = $('.employment_type_id').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(employment_type_id && employment_type_id !== ''){
			url += `${prefix}${window.const.PARAM.EMPLOYMENT_TYPE_ID}=${employment_type_id}`;
			prefix = '&';
		}
		if($('#beginner_flag').prop('checked')){
			const unmentioned_experience = $('#beginner_flag').val();
			url += `${prefix}${window.const.PARAM.UNMENTIONED_EXPERIENCE}=${unmentioned_experience}`;
			prefix = '&';
		}
		const salaryType = $('.input-select__select--salary-type').val();
		if(salaryType && salaryType !== '') {
			url += `${prefix}${window.const.PARAM.SALARY_TYPE}=${salaryType}`;
			prefix = '&';
			if(salaryType === '1') {
				const salary = $('.minimum-monthly-salary').find('.input-select__select').val();
				url += `${prefix}${window.const.PARAM.MINIMUM_MONTHLY_SALARY}=${salary}`;
				prefix = '&';
			}else if(salaryType === '2'){
				const salary = $('.minimum-daily-salary').find('.input-select__select').val();
				url += `${prefix}${window.const.PARAM.MINIMUM_DAILY_SALARY}=${salary}`;
				prefix = '&';
			}else if(salaryType === '3'){
				const salary = $('.minimum-hourly-salary').find('.input-select__select').val();
				url += `${prefix}${window.const.PARAM.MINIMUM_HOURLY_SALARY}=${salary}`;
				prefix = '&';
			}else if(salaryType === '4'){
				const salary = $('.minimum-project-fee').val();
				url += `${prefix}${window.const.PARAM.MINIMUM_PROJECT_FEE}=${salary}`;
				prefix = '&';
			}
		}
		let freeWord = $('.freeword').val();
		if(freeWord && freeWord !== ''){
			freeWord = encodeURIComponent(freeWord);
			url += `${prefix}${window.const.PARAM.FREEWORD}=${freeWord}`;
			prefix = '&';
		}
		const dc_property_type_office = $('.dc_property_type_office').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(dc_property_type_office && dc_property_type_office !== ''){
			url += `${prefix}${window.const.PARAM.PROPERTY_OFFICE}=${dc_property_type_office}`;
			prefix = '&';
		}
		const software1 = $('.software1').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(software1 && software1 !== ''){
			url += `${prefix}${window.const.PARAM.SOFTWARE1}=${software1}`;
			prefix = '&';
		}
		const software2 = $('.software2').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(software2 && software2 !== ''){
			url += `${prefix}${window.const.PARAM.SOFTWARE2}=${software2}`;
			prefix = '&';
		}
		const software3 = $('.software3').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(software3 && software3 !== ''){
			url += `${prefix}${window.const.PARAM.SOFTWARE3}=${software3}`;
			prefix = '&';
		}
		const view_special_condition = $('.view_special_condition').map((idx, e) => {
			const $e = $(e);
			if($e.prop('checked')){
				return $e.val();
			}
		}).get().join('|');
		if(view_special_condition && view_special_condition !== ''){
			url += `${prefix}${window.const.PARAM.VIEW_SPECIAL_CONDITION}=${view_special_condition}`;
			prefix = '&';
		}

		// sort
		if($('.list--search-sort')[0]){
			const $listSearchSort = $('.list--search-sort');
			const defaultSort = $listSearchSort.attr('data-default-sort');
			const active = $listSearchSort.find('.active').attr('data-sort');
			if(defaultSort !== active) {
				url += `${prefix}sort=${active}`;
				prefix = '&';
			}
		}

		// pagination
		if($('.pagination')[0]){
			if(page !== 1){
				url += `${prefix}page=${page}`;
			}
		}
		location.href = `${url}`;
	}


	/**
	 * グラフデータ取得
	 *
	 * @memberof Basic
	 * @return {undefined}
	 */
	getChartData() {
		console.log('Form.getChartData');
		this._model.getChartData();
	};


	/**
	 * ドロワーを閉じる
	 *
	 * @memberof Basic
	 * @return {undefined}
	 */
	closeDrawer() {
		console.log('Form.closeDrawer');
		this._listener.trigger('closeDrawer');
	};


	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
