'use strict';

/**
 * @classname RemoveNotNumber
 * @classdesc アコーディオン
 */
export default class RemoveNotNumber {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('RemoveNotNumber.constructor');
		this._$e = $e;
		$e.on('keyup', () => this.onKeyup());
	};


	/**
	 * チェンジイベントのコールバック
	 *
	 * @memberof RemoveNotNumber
	 * @return {undefined}
	 */
	onKeyup() {
		console.log('RemoveNotNumber.onKeyup');
		let value = this._$e.val();
		value = value.replace(/[^0-9]/g, '');
		this._$e.val(value);
	};
};