/**
 * @file 年設定
 */
'use strict';

/**
 * @classname SetYear
 * @classdesc 年設定
 */
export default class SetYear {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('SetYear.constructor');
		this._$e = $e;
		const date = new Date();
		const year = date.getFullYear();
		const type = $e.attr('data-type');
		let years = '<option value="">選択してください</option>';
		let start, end, selected;
		if (type === 'birth-year') {
			start = year - 90;
			end = year - 14;
			if($e.attr('data-value') && $e.attr('data-value') !== ''){
				selected = parseInt($e.attr('data-value'));
			}
		}else if(type === 'resume'){
			start = year - 65;
			end = year;
			selected = year;
			if($e.attr('data-value') && $e.attr('data-value') !== ''){
				selected = parseInt($e.attr('data-value'));
			}else if($e.prop('disabled')){
				selected = '';
			}
		}else{
			console.log('graduate year');
			start = year - 75;
			end = year + 4;
			if($e.attr('data-value') && $e.attr('data-value') !== ''){
				selected = parseInt($e.attr('data-value'));
			}
		}
		if(type === 'resume'){
			// resumeの場合は選択してくださいも消す
			years = `<option value="">--</option>`;
			for(let i = end; i > start; i--){
				// resumeの場合は新規追加しかないのでselectedはいらない
				// if(i === selected){
				// 	years += `<option value="${i}" selected>${i}</option>`;
				// }else{
				years += `<option value="${i}">${i}</option>`;
				// }
			}
		}else{
			for(let i = start; i < end; i++){
				if(i === selected){
					years += `<option value="${i}" selected>${i}</option>`;
				}else{
					years += `<option value="${i}">${i}</option>`;
				}
			}
		}
		this._$e.html(years);
	};
}