'use strict';

/**
 * @classname SpMenu
 * @classdesc TOPパララックス
 */
export default class SpMenu {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {instance} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('SpMenu.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$drawer = $('.drawer');
		this._$background = $('.drawer__background');
		this._$btn = $('.btn--close');
		$e.on('click', () => this.open());
		this._$btn.on('click', () => this.close());
		controller.on('closeDrawer', () => this.close());
	};


	/**
	 * メニューを開く
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	open() {
		console.log('SpMenu.open');
		this._$background.fadeIn();
		this._$drawer.addClass('open');
	};


	/**
	 * メニューを閉じる
	 *
	 * @memberof SpMenu
	 * @return {undefined}
	 */
	close() {
		console.log('SpMenu.close');
		this._$background.fadeOut();
		this._$drawer.removeClass('open');
	};
};
