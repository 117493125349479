'use strict';

/**
 * @classname BtnClose
 * @classdesc アラートクローズボタン
 */
export default class BtnClose {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {Object} controller - controller オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnClose.constructor');
		this._$e = $e;
		this._$alert = $e.closest('.alert');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof BtnClose
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnClose.onClick');
		this._$alert.fadeOut(500);
		setTimeout(() => {
			this._$alert.remove();
		}, 1000);
	};
};