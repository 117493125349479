'use strict';

/**
 * @classname Modal
 * @classdesc モーダルを表示する
 */
export default class Modal {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Modal.constructor');
		this._$e = $e;
		const modal = $e.attr('data-target');
		this._$modal = $(modal);
		$e.on('click', () => this.show());
		this._$modal.on('click', () => this.hide());
		this._$modal.find('.modal__document').on('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
		});
		this._$modal.find('.btn--close').on('click', () => this.hide());
		this._$modal.find('.anchor').on('click', (e) => this.link(e));
		this._$modal.find('a.btn').on('click', (e) => this.link(e));
	};


	/**
	 * モーダルを表示する
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	show() {
		if(this._$e.hasClass('blocked')) return;
		console.log('Modal.show');
		this._$modal.css('display', 'flex').animate({'opacity': '1'}, 500);
	};


	/**
	 * モーダルを非表示にする
	 *
	 * @memberof Modal
	 * @return {undefined}
	 */
	hide() {
		console.log('Modal.hide');
		this._$modal.fadeOut(500);
		setTimeout(() => {
			this._$modal.css('opacity', '0');
		},600);
	};


	/**
	 * 画面遷移する
	 *
	 * @memberof Modal
	 * @param {event} e - イベント
	 * @return {undefined}
	 */
	link(e) {
		console.log('Modal.link');
		const $e = $(e.currentTarget);
		const link = $e.attr('href');
		if(link !== 'javascript:void(0);') location.href = link;
	};
};