/**
 * @file BasicModel Controller
 */

'use strict';
import Listener from '../util/_listener';
import Common from '../util/_common';

let instance = null;

/**
 * @classname BasicModel
 * @classdesc Basic Model
 */
export default class BasicModel {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		this._listener = new Listener();
		this._jqXHR = null;
		this._post = false;
		this._data = null;
		this._message = null;
		this._code = null;
		this._detailPage = false;

		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		instance = this;
		return instance;
	};


	/**
	 *
	 * お気に入り
	 * @memberof BasicModel
	 * @param {string} jobId - 求人ID
	 * @return {undefined}
	 */
	favorite(jobId) {
		if(this._post) return;
		console.log(`BasicModel.favorite`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('job_id', jobId);
		const url = window.const.API_URL.FAVORITE;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onFavoriteComplete.bind(this));
	};


	/**
	 * お気に入りajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onFavoriteComplete(data, textStatus) {
		console.log('BasicModel.onFavoriteComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(`${this._message}`);
				this._listener.trigger('favoriteError');
			}else{
				this._post = false;
				this._listener.trigger('favoriteComplete');
			}
		}else{
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(this._message);
			this._listener.trigger('favoriteError');
			console.log(`ajax error (onPostMessageComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * ブロック
	 * @memberof BasicModel
	 * @param {string} jobId - 求人ID
	 * @return {undefined}
	 */
	block(jobId) {
		if(this._post) return;
		console.log(`BasicModel.block / ${jobId}`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('job_id', jobId);
		const url = window.const.API_URL.BLOCK;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onBlockComplete.bind(this));
	};


	/**
	 * ブロックajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onBlockComplete(data, textStatus) {
		console.log('BasicModel.onBlockComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(`${this._message}`);
			}else{
				this._post = false;
				if(this._detailPage){
					this._listener.trigger('blockOffComplete');
				}else{
					this._listener.trigger('blockComplete');
				}
			}
			this._detailPage = false;
		}else{
			console.log(`ajax error (onPostMessageComplete): ${textStatus}`);
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(this._message);
			this._detailPage = false;
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * ブロック解除（詳細ページ）
	 * @memberof BasicModel
	 * @param {string} jobId - 求人ID
	 * @return {undefined}
	 */
	blockOff(jobId) {
		if(this._post) return;
		console.log(`BasicModel.blockOff`);
		this._detailPage = true;
		this.block(jobId);
	};


	/**
	 *
	 * メッセージ投稿
	 * @memberof BasicModel
	 * @param {string} message - メッセージ
	 * @return {undefined}
	 */
	postMessage(message) {
		if(this._post) return;
		console.log(`BasicModel.postMessage`);
		console.log(message);
		Common.showLoading();
		this._post = true;
		const $btn = $('.btn--post-message');
		const boardId = $btn.attr('data-board-id');
		const companyId = $btn.attr('data-company-id');
		const seekerId = $btn.attr('data-seeker-id');
		let formData = new FormData();
		formData.append('message', message);
		formData.append('id', boardId);
		formData.append('company_id', companyId);
		formData.append('seeker_id', seekerId);
		const url = window.const.API_URL.POST_MESSAGE;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onPostMessageComplete.bind(this));
	};


	/**
	 * メッセージ投稿ajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onPostMessageComplete(data, textStatus) {
		console.log('BasicModel.onPostMessageComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(`${this._message}`);
				this._listener.trigger('error');
			}else{
				this._post = false;
				this._listener.trigger('postMessageComplete');
			}
		}else{
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onPostMessageComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * 画像アップロード
	 * @memberof FormModel
	 * @param {blob} file - ファイル
	 * @return {undefined}
	 */
	postAttachment(file) {
		if(this._post) return;
		console.log(`FormModel.uploadImage`);
		Common.showLoading();
		this._post = true;
		const $btn = $('.btn--post-message');
		const boardId = $btn.attr('data-board-id');
		const companyId = $btn.attr('data-company-id');
		const seekerId = $btn.attr('data-seeker-id');
		let formData = new FormData();
		formData.append('attachment', file);
		formData.append('id', boardId);
		formData.append('company_id', companyId);
		formData.append('seeker_id', seekerId);
		const url = window.const.API_URL.POST_ATTACHMENT;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onPostAttachmentComplete.bind(this));
	};


	/**
	 * 添付アップロードajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onPostAttachmentComplete(data, textStatus) {
		console.log('FormModel.onPostAttachmentComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(`${this._message}`);
				this._listener.trigger('error');
			}else{
				this._post = false;
				this._listener.trigger('postAttachmentComplete');
			}
		}else{
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onPostAttachmentComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * グラフデータ取得
	 * @memberof BasicModel
	 * @return {undefined}
	 */
	getChartData() {
		if(this._post) return;
		console.log(`BasicModel.getChartData`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		const url = window.const.API_URL.GET_CHART_DATA;
		let params = {};
		params['data'] = formData;
		params['method'] = 'GET';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetChartDataComplete.bind(this));
	};


	/**
	 * グラフデータ取得完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetChartDataComplete(data, textStatus) {
		console.log('BasicModel.onGetChartDataComplete');
		Common.hideLoading();
		console.log(data);
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if(textStatus === 'success'){
			if(this._code !== 0){
				this._post = false;
				Common.showErrorModal(this._message);
			}else{
				this._post = false;
				this._listener.trigger('getChartDataComplete');
			}
		}else{
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(this._message);
			console.log(`ajax error (onGetChartErrorComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof BasicModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof BasicModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
