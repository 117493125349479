/**
 * @file 個人情報保護方針同意
 */
'use strict';

/**
 * @classname Agree
 * @classdesc 個人情報保護方針同意
 */
export default class Agree {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Agree.constructor');
		this._$e = $e;
		this._$btn = $('.btn[data-form]');
		this._$reason = $('#application_reason');
		this._$email = $('#email');
		this._$password = $('#password');
		if(this._$e.closest('#form-reason-apply')[0]){
			$e.on('click', () => this.check());
		}else{
			$e.on('click', () => this.check2());
		}
		this._$reason.on('blur', () => this.check());
		this._$email.on('blur', () => this.check2());
		this._$password.on('blur', () => this.check2());
	};



	/**
	 * ボタンが押せる状態か確認（志望動機画面）
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	check() {
		console.log('Agree.onClick');
		const reason = this._$reason.val();
		setTimeout(() => {
			if(	reason &&
				reason !== '' &&
				!this._$reason.hasClass('is-invalid') &&
				this._$e.prop('checked'))
			{
				this._$btn.removeClass('disabled');
			}else{
				this._$btn.addClass('disabled');
			}},100);
	};


	/**
	 * ボタンが押せる状態か確認
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	check2() {
		console.log('Agree.check');
		const email = this._$email.val();
		const password = this._$password.val();
		setTimeout(() => {
		if(	email &&
			email !== '' &&
			!this._$email.hasClass('is-invalid') &&
			password &&
			password !== '' &&
			!this._$password.hasClass('is-invalid') &&
			this._$e.prop('checked'))
		{
			this._$btn.removeClass('disabled');
		}else{
			this._$btn.addClass('disabled');
		}},100);
	};
}
