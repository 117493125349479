/**
 * @file カナ自動入力
 *
 */
'use strict';

/**
 * @classname AutoKanaView
 * @classdesc カナ自動入力
 */
export default class AutoKanaView {
	/**
	 * @constructor
	 * @param {jQuery} $name - 名前オブジェクト
	 * @param {jQuery} $nameKana - 名前かなオブジェクト
	 */
	constructor($name, $nameKana) {
		console.log('AutoKanaView.constructor');
		this._$name = $name;
		this._$nameKana = $nameKana;
		this._kana_extraction_pattern = new RegExp('[^ 　ぁあ-んー]', 'g');
		this._kana_compacting_pattern = new RegExp('[ぁぃぅぇぉっゃゅょ]', 'g');
		this._active = true;
		this._timer = null;
		this._flagConvert = true;
		this._input = null;
		this._values = null;
		this._ignoreString = null;
		this._baseKana = null;
		this.stateClear();
		this._$name.on('blur', () => {this.onBlur();});
		this._$name.on('focus', () => {this.onFocus();});
		this._$name.on('keydown', () => {this.onKeyDown();});
	};


	/**
	 * blurのコールバック
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	onBlur() {
		console.log('AutoKanaView.onBlur');
		this.clearInterval();
		if(this._$name.val() !== ''){
			this._$nameKana.focus().blur();
		}
	};


	/**
	 * フォーカスのコールバック
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	onFocus() {
		console.log('AutoKanaView.onFocus');
		this.stateInput();
		this.setInterval();
	};


	/**
	 * keydownのコールバック
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	onKeyDown() {
		console.log('AutoKanaView.onKeyDown');
		if (this._flagConvert) {
			this.stateInput();
		}
	};


	/**
	 * ステータスクリア
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	stateClear() {
		console.log('AutoKanaView.stateClear');//
		this._baseKana = '';
		this._flagConvert = false;
		this._ignoreString = '';
		this._input = '';
		this._values = [];
	};


	/**
	 * 入力中
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	stateInput() {
		console.log('AutoKanaView.stateInput');//
		this._baseKana = this._$nameKana.val();
		this._flagConvert = false;
		this._ignoreString = this._$name.val();
	};


	/**
	 * 変換中
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	stateConvert() {
		console.log('AutoKanaView.stateConvert');//
		this._baseKana = this._baseKana + this._values.join('');
		this._flagConvert = true;
		this._values = [];
	};


	/**
	 * タイマー設定
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	setInterval() {
		console.log('AutoKanaView.setInterval');//
		this._timer = setInterval(() => {this.checkValue()}, 20);
	};


	/**
	 * タイマー解除
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	clearInterval() {
		console.log('AutoKanaView.clearInterval');
		clearInterval(this._timer);
	};


	/**
	 * ひらがな確認
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	isHiragana(chara) {
		console.log('AutoKanaView.isHiragana');
		return ((chara >= 12353 && chara <= 12435) || chara == 12445 || chara == 12446);
	};


	/**
	 * 変換確認
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	checkConvert(new_values) {
		console.log('AutoKanaView.checkConvert');
		if (!this._flagConvert) {
			if (Math.abs(this._values.length - new_values.length) > 1) {
				let tmp_values = new_values.join('').replace(this._kana_compacting_pattern, '').split('');
				if (Math.abs(this._values.length - tmp_values.length) > 1) {
					this.stateConvert();
				}
			} else {
				if (this._values.length === this._input.length && this._values.join('') !== this._input) {
					if (this._input.match(this._kana_extraction_pattern)) {
						this.stateConvert();
					}
				}
			}
		}
	};


	/**
	 * 値確認
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	checkValue() {
		console.log('AutoKanaView.checkValue');//
		let new_input, new_values;
		new_input = this._$name.val();
		if (new_input === '') {
			this.stateClear();
			this.setKana();
		} else {
			new_input = this.removeString(new_input);
			if (this._input === new_input) {
				return;
			} else {
				this._input = new_input;
				if (!this._flagConvert) {
					new_values = new_input.replace(this._kana_extraction_pattern, '').split('');
					this.checkConvert(new_values);
					this.setKana(new_values);
				}
			}
		}
	};


	/**
	 * 文字列削除
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	removeString(new_input) {
		console.log('AutoKanaView.removeString');
		if (new_input.indexOf(this._ignoreString) !== -1) {
			return new_input.replace(this._ignoreString, '');
		} else {
			let i, ignoreArray, inputArray;
			ignoreArray = this._ignoreString.split('');
			inputArray = new_input.split('');
			for (i = 0; i < ignoreArray.length; i++) {
				if (ignoreArray[i] === inputArray[i]) {
					inputArray[i] = '';
				}
			}
			return inputArray.join('');
		}
	};


	/**
	 * カナ設定
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	setKana(new_values) {
		console.log('AutoKanaView.setKana');
		if (!this._flagConvert) {
			if (new_values) {
				this._values = new_values;
			}
			if (this._active) {
				let val = this.toKatakana(this._baseKana + this._values.join(''));
				this._$nameKana.val(val);
			}
		}
	};


	/**
	 * ひらがな→カタカナ変換
	 *
	 * @memberof AutoKanaView
	 * @return {undefined}
	 */
	toKatakana(src) {
		console.log('AutoKanaView.toKatakana');
		let c, i, str;
		str = new String;
		for (i = 0; i < src.length; i++) {
			c = src.charCodeAt(i);
			if (this.isHiragana(c)) {
				str += String.fromCharCode(c + 96);
			} else {
				str += src.charAt(i);
			}
		}
		return str;
	};
}
