'use strict';

// utility class
import ApplyClass from '../util/_apply-class';

import Form from '../controller/_form';

import Validate from '../view/form/_validate';
import ValidateCheckbox from '../view/form/_validate-checkbox';
import Submit from '../view/form/_submit';
import Agree from '../view/form/_agree';
import ShowPassword from '../view/form/_show-password';
import ZenkakuToHankaku from '../view/form/_zenkaku-to-hankaku';
import RemoveNotNumber from '../view/form/_remove-not-number';
import HasCounter from '../view/form/_has-counter';
import AutoKana from '../view/form/_auto-kana';
import SetYear from '../view/form/_set-year';
import DateChange from '../view/form/_date-change';

import ChangeUserType from '../view/form/_change-user-type';
import ChangeOverseasFlg from '../view/form/_change-overseas-flg';
import ChangeInvoiceFlg from '../view/form/_change-invoice-flg';

import BtnChangeNum from '../view/form/_btn-change-num';
import ChangeGift from '../view/form/_change-gift';
import SelectProductType from '../view/form/_select-product-type';
import ChangeCartQuantity from '../view/form/_change-cart-quantity';
import RemoveCartProduct from '../view/form/_remove-cart-product';

$(() => {
	const FormController = new Form();
	// validate
	$('.validate').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Validate, $e, [$e, FormController]);
	});
	// validate checkbox
	$('.validate-checkbox').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ValidateCheckbox, $e, [$e, FormController]);
	});
	// submit
	$('.btn[type=submit][data-form]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Submit, $e, [$e, FormController]);
	});
	// 規約に同意
	$('#agree').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Agree, $e, [$e, FormController]);
	});
	// パスワード表示/非表示切替
	$('.anchor--show-password').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ShowPassword, $e, [$e, FormController]);
	});
	// 全角→半角変換
	$('.zen2han').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ZenkakuToHankaku, $e, [$e, FormController]);
	});
	// 数字以外削除
	$('.remove-not-number').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(RemoveNotNumber, $e, [$e, FormController]);
	});
	// カウンター
	$('.has-counter').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(HasCounter, $e, [$e, FormController]);
	// 年セット
	$('.year').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SetYear, $e, [$e, FormController]);
	});
	// 年月変更
	$('.date-change').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(DateChange, $e, [$e, FormController]);
	});

	/* 登録関連 */
	});
	// カナ自動入力
	$('#name_sei').each(() => {
		const $nameSei = $('#name_sei');
		const $nameMei = $('#name_mei');
		const $nameSeiKana = $('#name_sei_kana');
		const $nameMeiKana = $('#name_mei_kana');
		const $staffName = $('#staff_name');
		const $staffNameKana = $('#staff_name_kana');
		ApplyClass.apply(AutoKana, $nameSei, [$nameSei, $nameSeiKana]);
		ApplyClass.apply(AutoKana, $nameMei, [$nameMei, $nameMeiKana]);
		ApplyClass.apply(AutoKana, $staffName, [$staffName, $staffNameKana]);
	});
	// ユーザータイプ変更
	$('.user_type').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeUserType, $e, [$e, FormController]);
	});
	// 海外住所変更
	$('.oversea_flg').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeOverseasFlg, $e, [$e, FormController]);
	});
	// 請求書フラグ変更
	$('.invoice_flg').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeInvoiceFlg, $e, [$e, FormController]);
	});


	/* カート関連 */

	// カート数量変更
	$('.btn--change-num').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(BtnChangeNum, $e, [$e, FormController]);
	});
	// ギフト変更
	$('.deliver_to').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeGift, $e, [$e, FormController]);
	});
	// カート数量変更
	$('.list--detail-btn .list__item').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SelectProductType, $e, [$e, FormController]);
	});
	// カート数量変更
	$('.input-select__select--cart-quantity').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ChangeCartQuantity, $e, [$e, FormController]);
	});
	// カート商品削除
	$('.btn--cart-product-delete').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(RemoveCartProduct, $e, [$e, FormController]);
	});
});
