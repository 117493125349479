/**
 * @file カート数量変更
 */
'use strict';

/**
 * @classname ChangeCartQuantity
 * @classdesc カート数量変更
 */
export default class ChangeCartQuantity {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('ChangeCartQuantity.constructor');
		this._$e = $e;
		this._controller = controller;
		this._unitPrice = parseInt($e.attr('data-unit-price'));
		$e.on('change', () => this.onChange());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onChange() {
		console.log('ChangeCartQuantity.onChange');
		const quantity = parseInt(this._$e.val());
		const totalPrice = quantity * this._unitPrice;
		this._$e.closest('.list__item').find('.text--total strong').text(`${totalPrice.toLocaleString()}`);
		this._controller.calculatePrice();
	};
}
