'use strict';

/**
 * @classname Accordion
 * @classdesc アコーディオン
 */
export default class Accordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Accordion.constructor');
		this._$e = $e;
		this._$target = $(this._$e.attr('data-target'));
		this._hide = $e.attr('data-hide');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof Accordion
	 * @return {undefined}
	 */
	onClick() {
		console.log('Accordion.onClick');
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open');
			this._$target.slideUp().removeClass('open');
		}else{
			this._$e.addClass('open');
			this._$target.slideDown().addClass('open');
		}
		if(this._hide === 'true'){
			this._$e.remove();
		}
	};
};
