'use strict';

/**
 * @classname Search
 * @classdesc 検索
 */
export default class Search {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('Search.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof Search
	 * @return {undefined}
	 */
	onClick() {
		console.log('Search.onClick');
		if(this._$e.hasClass('btn--sp')){
			this.searchSp();
		}else{
			this.searchPc();
		}
	};


	/**
	 * 検索(SP)
	 *
	 * @memberof Search
	 * @return {undefined}
	 */
	searchSp() {
		console.log('Search.searchSp');
		let url = window.const.URL.SEARCH;
		let prefix = '?';
		const area = $('#location-sp').val();
		if(area !== ''){
			url += `${prefix}area=${area}`;
			prefix = '&';
		}
		const occupation = $('#occupation-sp').val();
		if(occupation !== ''){
			url += `${prefix}occupation=${occupation}`;
			prefix = '&';
		}
		const employment_type = $('#employment_type-sp').val();
		if(employment_type !== ''){
			url += `${prefix}employment_type=${employment_type}`;
			prefix = '&';
		}
		const search = $('#search-sp').val();
		if(search !== ''){
			url += `${prefix}q=${search}`;
		}
		location.href = url;
	};


	/**
	 * 検索(PC)
	 *
	 * @memberof Search
	 * @return {undefined}
	 */
	searchPc() {
		console.log('Search.searchPc');
		let url = window.const.URL.SEARCH;
		let prefix = '?';
		const area = $('#location').val();
		if(area !== ''){
			url += `${prefix}area=${area}`;
			prefix = '&';
		}
		const occupation = $('#occupation').val();
		if(occupation !== ''){
			url += `${prefix}occupation=${occupation}`;
			prefix = '&';
		}
		const employment_type = $('#employment_type').val();
		if(employment_type !== ''){
			url += `${prefix}employment_type=${employment_type}`;
			prefix = '&';
		}
		const search = $('#search').val();
		if(search !== ''){
			url += `${prefix}q=${search}`;
		}
		location.href = url;
	};
};
