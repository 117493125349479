/**
 * @file _form-pre-regist.js
 */
'use strict';

/**
 * @classname FormPreRegist
 * @classdesc 仮登録
 */
export default class FormPreRegist {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormPreRegist
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormPreRegist.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormPreRegist
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormPreRegist.postProcessing');
		// バリデーション後に行う処理を記載（開始）
		console.log(this._controller);

		const email = $('#email').val();
		const divided = email.split('@');
		const $email1 = $('#email1');
		const $email2 = $('#email2');
		const $emailType = $('#email_type');
		if(divided[1].match(/\.co\.jp/)){
			const email2 = divided[1].replace(/\.co\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.co.jp');
		}else if(divided[1].match(/\.net/)){
			const email2 = divided[1].replace(/\.net/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.net');
		}else if(divided[1].match(/\.ne\.jp/)){
			const email2 = divided[1].replace(/\.ne\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.ne.jp');
		}else if(divided[1].match(/\.or\.jp/)){
			const email2 = divided[1].replace(/\.or\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.or.jp');
		}else if(divided[1].match(/\.ac\.jp/)){
			const email2 = divided[1].replace(/\.ac\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.ac.jp');
		}else if(divided[1].match(/\.lg\.jp/)){
			const email2 = divided[1].replace(/\.lg\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.lg.jp');
		}else if(divided[1].match(/\.jp/)){
			const email2 = divided[1].replace(/\.jp/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.jp');
		}else if(divided[1].match(/\.com/)){
			const email2 = divided[1].replace(/\.com/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.com');
		}else if(divided[1].match(/\.org/)){
			const email2 = divided[1].replace(/\.org/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.org');
		}else if(divided[1].match(/\.biz/)){
			const email2 = divided[1].replace(/\.biz/, '');
			$email1.val(divided[0]);
			$email2.val(email2);
			$emailType.val('.biz');
		}else{
			const reg = /^(\w+)\.(.+)/;
			const matches = divided[1].match(reg);
			$email1.val(divided[0]);
			$email2.val(matches[1]);
			$emailType.val(matches[2]);
			console.log(`${$email1.val()} / ${$email2.val()} / ${$emailType.val()}`);
		}

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
