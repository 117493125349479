/**
 * @file _form-regist.js
 */
'use strict';

/**
 * @classname FormRegist
 * @classdesc 会員本登録、会員情報編集
 */
export default class FormRegist {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormRegist
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormRegist.preProcessing');
		// バリデーション後に行う処理を記載（開始）
		let userType;

		if($('.user_type').attr('type') === 'hidden'){
			userType = $('.user_type').val();
		}else{
			userType = $('.user_type:checked').val();
		}
		if(userType === '1'){
			$('.block--company').find()
		}
		

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormRegist
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormRegist.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		
		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}
