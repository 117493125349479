/**
 * @file 上下スクロールバーを連動
 *
 */
'use strict';

/**
 * @classname TableScrollbar
 * @classdesc 上下スクロールバーを連動
 */
export default class TableScrollbar {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableScrollbar.constructor');
		this._$e = $e;
		this._$clone = $e.closest('.table-wrap').find('.scroll-box');
		this._$inner = $e.find('.scroll-bar-inner');
		this._width = parseInt($('.table').css('min-width'));
		$e.on('scroll', () => {
			this._$clone.scrollLeft($e.scrollLeft());
		});
		this._$clone.on('scroll', () => {
			$e.scrollLeft(this._$clone.scrollLeft());
		});
		this.show();
		$(window).on('resize', () => this.show());
	};


	/**
	 * 表示・非表示
	 *
	 * @memberof FormSelectToggleView
	 * @return {undefined}
	 */
	show() {
		console.log('TableScrollbar.show');
		console.log(this._$clone.width());
		this._$inner.width(this._width);
		if(this._$clone.width() >= this._width){
			this._$e.hide();
		}else{
			this._$e.show();
		}
	};
}